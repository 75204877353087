import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout checkjump="privacy/facility_terms_of_use" checken="en">
      <SEO  checken="en" title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'FACILLITY TERMS OF USE',
            sub: 'Facility Terms of Use',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/privacy/site/kv.png',
              },
              imgSp: {
                src: '/assets/images/privacy/site/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          checken="en"
          data={{
            parent: [
              {
                label: 'Terms and Conditions',
                path: '/en/privacy/',
              },
            ],
            current: {
              label: 'Facility Terms of Use',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <h2 className="c_headingLv2">YOKOHAMA ROYAL PARK HOTEL FACILITIES USAGE POLICY</h2>
          <p className="u_mb25">
          To ensure the safety and comfort of all guests of this hotel, guests must comply with the terms of this facilities usage policy.<br />Hotel guests that do not comply with this policy will be prohibited from using the hotel and its facilities.To ensure the safety and comfort of all guests of this hotel, guests must comply with the terms of this facilities usage policy.<br />Hotel guests that do not comply with this policy will be prohibited from using the hotel and its facilities.
          </p>
          <h3 className="c_headingLv5">1. Scope</h3>
          <p className="u_mb25">
          This policy is applicable to guests who use any of the hotel facilities. Facilities include hotel rooms, banquet rooms, restaurants, bars, the landmark spa, coatroom, lobby, driveways, and all other areas of the hotel site. Hereinafter, these facilities will be collectively referred to as the hotel facilities. This policy overrides any conflicts with other separate policies applicable to the hotel rooms, banquet rooms, and special events.
          </p>
          <h3 className="c_headingLv5">2.  Fire Prevention and Safety</h3>
          <p className="u_mb25">
          (1) Smoking is strictly prohibited except in designated smoking areas.
            <br />
            (2) Only hotel guests are allowed entry into the backyard, emergency stairs, equipment rooms, and other similar areas.
          </p>
          <h3 className="c_headingLv5">
            3.  Handling of Lost Items or Entrusted Items Stored at the Hotel
          </h3>
          <p className="u_mb25">
          (1) Items entrusted to the hotel will be kept for 3 months from the date of initial receipt. These items will be discarded after this storage period.
            <br />
            (2) Lost and found items will be kept by the hotel in accordance with applicable laws and regulations.
          </p>
          <h3 className="c_headingLv5">
            4.  Prohibition of Facilities Usage by Antisocial Organizations
          </h3>
          <p>
          (1) The following organizations and members of these organizations are prohibited from using the hotel facilities.
          </p>
          <ul className="c_indentList">
            <li> (a) Gangs, gang members, gang affiliates, and any other related parties</li>
            <li>
            (b) Companies or other organizations that are engaged in criminal activity or that employ gangs or gang members
            </li>
            <li> (c) Antisocial organizations, members of antisocial organizations, and any related parties</li>
            <li>
            (d) Any guests that have performed actions of violence, blackmail, extortion, intimidation, or have caused injury to others
            </li>
            <li>
            (e) Persons who are a danger to both themselves and others or who generate fear or anxiety in others due to factors including, but not limited to, having diminished mental capacity, being under the influence of drugs, or suffering from depression
            </li>
            <li>
            (f) Guests who do not immediately refrain from performing the actions listed in section 5 “Other Prohibited Actions” upon warning from the hotel
            </li>
          </ul>
          <p className="u_mb25">
          (2) Any organization or individual that is found to correspond to any of definitions
          </p>

          <p className="u_mb25">
          4.1.a through 4.1.c will immediately be prohibited from using any hotel facilities.
          </p>

         
          <h3 className="c_headingLv5">5.  Other Prohibited Actions</h3>
          <p>
          (1) Speaking or singing loudly, making noise, or causing other disturbances in hotel facilities that inconvenience other guests.
            <br />
            (2) Bringing animals or items into the hotel facilities that may inconvenience other guests.
          </p>
          <ul className="c_indentList">
            <li>
            (a) Animals and pets including dogs, cats, small birds, and similar (excluding guide/assistance dogs)
            </li>
            <li>
            (b) Ignition sources, igniters or other flammable objects, volatile oil products, potentially dangerous products, objects that emit odors, large inventories of goods, and any other objects for which possession is prohibited by law
            </li>
          </ul>
          <p className="u_mb15">
            (3) Gambling or engaging in immoral or unsafe activity in hotel facilities.
            <br />
            (4) Soliciting, advertising, distributing promotional materials, selling merchandise, or performing other commercial activities in hotel facilities without permission.
            <br />
            (5) Distributing documentation or petitioning for signatures in hotel facilities without permission.
            <br />
            (6) Leaving belongings in hallways, the lobby, or other hotel areas.
            <br />
            (7) Moving various objects in hotel facilities to other locations or taking them outside of hotel facilities.
            <br />
            (8) Damaging or modifying hotel facilities or installing other objects in the hotel facilities.
            <br />
            (9) Arriving at the hotel in or parking of modified vehicles, vehicles used for propaganda purposes, or any other vehicle that may inconvenience other guests as determined by the hotel.
            <br />
            (10) Any other inappropriate activities as determined by the hotel.
          </p>
          <p className="u_mb25">
          This policy is effective as of March 1, 2007.
          </p>
          <p className="u_tar">
          Established: March 1, 2007
            <br />
            Revised: August 1, 2008

            <br />
            Revised: May 9, 2023
          </p>
        </LWrap>
      </section>
      {/* <ConceptMedia /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
